import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { of, timer, zip } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromGenerated from '../../_generated';
import { layoutFeature, LayoutState } from '../../layout/store/layout.reducer';
import * as fromAssistants from '../assistants';
import { LOADING_TIME_FOR_MESSAGE } from '../chats.constants';
import { ChatsSocket } from '../chats.socket';
import {
  ChatActions,
  ChatsActions,
  ChatsSocketActions,
  ChatWithMessagesActions,
} from './chats.actions';
import { chatsFeature, ChatsState } from './chats.reducer';
const DOCUMENT_CITATIONS_LOADING_TIMEOUT_MS = 20000;

@Injectable()
export class ChatsEffects {
  private readonly actions$ = inject(Actions);
  private readonly chatsService = inject(fromGenerated.ChatsService);
  private readonly store = inject(Store<ChatsState>);
  private readonly layoutStore = inject(Store<LayoutState>);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly chatsSocket = inject(ChatsSocket);
  private readonly CONVERSATION_URL = '/chats/conversation';

  unselectChatOnNavigation$ = createEffect(() =>
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter((event) => {
        const previousUrl =
          this.router
            .getCurrentNavigation()
            ?.previousNavigation?.finalUrl?.toString() ?? '';
        return (
          previousUrl.includes(this.CONVERSATION_URL) &&
          !event.url.includes(this.CONVERSATION_URL)
        );
      }),
      map(() => ChatActions.unselectChat())
    )
  );

  receivedChatWithMessagesFromSocketSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess
        ),
        exhaustMap(async ({ chatWithMessages }) => {
          if (chatWithMessages.assistantId) {
            await this.router.navigate([this.CONVERSATION_URL]);
          }
        })
      ),
    { dispatch: false }
  );

  loadChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChats),
      exhaustMap(() =>
        this.chatsService.chatsControllerGetChats().pipe(
          map((chats) => ChatsActions.loadChatsSuccess({ chats })),
          catchError((error: Error) =>
            of(ChatsActions.loadChatsFailure({ error: error.message }))
          )
        )
      )
    )
  );

  loadChatsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatsSuccess),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      map(([{ chats }, selectedChatId]) =>
        ChatActions.selectChat({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: selectedChatId ?? chats.find((chat) => !chat._count.messages)!.id,
        })
      )
    )
  );

  selectChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.selectChat),
      withLatestFrom(
        this.store.select(
          fromAssistants.assistantsFeature.selectSelectedAssistantId
        )
      ),
      switchMap(([{ id }, selectedAssistantId]) =>
        selectedAssistantId
          ? [
              ChatWithMessagesActions.loadChatWithMessages({ id }),
              fromAssistants.AssistantActions.unselectAssistant(),
            ]
          : [ChatWithMessagesActions.loadChatWithMessages({ id })]
      )
    )
  );

  openThreadsListingContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.openThreadsListingContainer),
      withLatestFrom(this.layoutStore.select(layoutFeature.selectIsMobile)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, isMobile]) => isMobile),
      map(() => ChatActions.unselectChat())
    )
  );

  selectEmptyChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.selectEmptyChat),
      map(() => {
        return ChatActions.loadEmptyChat();
      })
    )
  );

  loadEmptyChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadEmptyChat),
      withLatestFrom(this.store.select(chatsFeature.selectAll)),
      switchMap(([, chats]) => {
        const emptyChat = chats.find(
          (chat) => chat && chat._count.messages === 0
        );
        /**
         * If there is no empty chat, we need to load the chats.
         * TODO: We should update this call once we have a proper
         * endpoint to load the empty chat.
         */
        if (!emptyChat) {
          return this.chatsService.chatsControllerGetChats().pipe(
            map(
              (chats) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                chats.find((chat) => !chat._count.messages)!
            )
          );
        }
        return of(emptyChat);
      }),
      map((chat) =>
        ChatActions.loadEmptyChatSuccess({
          chat,
        })
      ),
      catchError((error: Error) =>
        of(ChatActions.loadEmptyChatFailure({ error: error.message }))
      )
    )
  );

  loadChatWithMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.loadChatWithMessages),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChat)),
      switchMap(([{ id }, chat]) => {
        /**
         * If the chat is empty, we don't need to load the messages
         */
        if (chat && chat._count.messages === 0) {
          return of(
            ChatWithMessagesActions.loadChatWithMessagesSuccess({
              chatWithMessages: { ...chat, messages: chat.messages ?? [] },
            })
          );
        }

        return zip(
          this.chatsService.chatsControllerGetChatWithMessages(id),
          timer(LOADING_TIME_FOR_MESSAGE)
        ).pipe(
          /**
           * If the chat has an assistant & not a custom assistant,
           * we need to translate the first message.
           */
          map(([chatWithMessages]) =>
            fromAssistants.translateFirstMessageForAssistant(
              chatWithMessages,
              this.translateService
            )
          ),
          map((chatWithMessages) => {
            return ChatWithMessagesActions.loadChatWithMessagesSuccess({
              chatWithMessages,
            });
          }),
          catchError((error: Error) =>
            of(
              ChatWithMessagesActions.loadChatWithMessagesFailure({
                error: error.message,
              })
            )
          )
        );
      })
    )
  );

  addChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.addChatMessage),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, selectedChatId]) => !!selectedChatId),
      switchMap(([{ content, assistant }, selectedChatId]) =>
        this.chatsService
          .chatsControllerAddChatMessage(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedChatId!,
            assistant ? { content, assistantUuid: assistant.uuid } : { content }
          )
          .pipe(
            switchMap(() =>
              assistant
                ? [
                    fromAssistants.AssistantActions.unselectAssistant(),
                    ChatWithMessagesActions.addChatMessageSuccess(),
                  ]
                : [ChatWithMessagesActions.addChatMessageSuccess()]
            ),
            catchError((error: Error) =>
              of(
                ChatWithMessagesActions.addChatMessageFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  requestDocumentCitations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.requestDocumentCitations),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, selectedChatId]) => !!selectedChatId),
      switchMap(([{ chatMessageId, documentUuid }, selectedChatId]) => {
        return this.chatsService
          .chatsControllerGenerateDocumentCitations(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedChatId!,
            chatMessageId,
            documentUuid
          )
          .pipe(map(() => ({ chatMessageId, documentUuid, selectedChatId })));
      }),
      // Cancel other timers when clicking on another chip
      switchMap(({ chatMessageId, documentUuid, selectedChatId }) =>
        timer(DOCUMENT_CITATIONS_LOADING_TIMEOUT_MS).pipe(
          withLatestFrom(
            this.store.select(chatsFeature.selectIsCitationsLoading)
          ),
          map(([, isCitationsLoading]) => {
            if (isCitationsLoading) {
              Sentry.captureEvent({
                message: 'Document citations request timed out',
                level: 'error',
                extra: {
                  chatMessageId,
                  documentUuid,
                  selectedChatId,
                },
              });
            }
            return ChatWithMessagesActions.setCitationsLoading({
              isLoading: false,
            });
          })
        )
      ),
      catchError((error: Error) =>
        of(
          ChatWithMessagesActions.requestDocumentCitationsFailure({
            error: error.message,
          })
        )
      )
    )
  );

  requestQuickAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.requestQuickAction),
      switchMap(({ chatId, chatMessageId, quickAction, quickActionMessage }) =>
        this.chatsService
          .chatsControllerRequestQuickAction(chatId, chatMessageId, {
            quickAction,
            quickActionMessage,
          })
          .pipe(
            map(() => ChatWithMessagesActions.requestQuickActionSuccess()),
            catchError((error: Error) =>
              of(
                ChatWithMessagesActions.requestQuickActionFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  deleteChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteChat),
      switchMap(({ id }) =>
        this.chatsService.chatsControllerDeleteChatWithMessages(id).pipe(
          map(() => ChatsActions.deleteChatSuccess({ id })),
          catchError((error: Error) =>
            of(ChatsActions.deleteChatFailure({ error: error.message }))
          )
        )
      )
    )
  );

  deleteChatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteChatSuccess),
      map(() => ChatActions.loadEmptyChat())
    )
  );

  chatsSocketConnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatsSocketActions.reconnect),
        tap(() => {
          void this.chatsSocket.disconnect();
          void this.chatsSocket.connect((error) => console.error(error));
        })
      ),
    { dispatch: false }
  );
}
