import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import {
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService,
  PDFNotificationService,
  PdfSidebarView,
  RenderedTextLayerHighlights,
} from 'ngx-extended-pdf-viewer';
import * as fromFeatureFlags from '../../../feature-flags';
import {
  getThumbnails,
  getThumbnailsWithPins,
} from './document-viewer-content.helper';
import { resolvePromiseWithTimeout } from './document-viewer.helper';

export type DocumentViewerZoomSetting = string | number | undefined;
export type DocumentViewerZoomFactor = number | undefined;

@Component({
  selector: 'squadbox-document-viewer-content',
  imports: [NgxExtendedPdfViewerModule, fromSeriousSystem.SpinnerDirective],
  host: { '[class.is-mobile]': 'isMobile()' },
  template: `
    <!-- Spinner -->
    <div
      class="absolute inset-0 z-10 flex items-center justify-center bg-transparency-black-16"
      [class.hidden]="!isLoading()"
    >
      <div sdSpinner size="md"></div>
    </div>
    <!-- PDF Viewer -->
    <ngx-extended-pdf-viewer
      [src]="src()"
      [enableDragAndDrop]="false"
      [showToolbar]="false"
      [sidebarVisible]="!isMobile()"
      [activeSidebarView]="PdfSidebarView.THUMBS"
      [showBorders]="false"
      [handTool]="false"
      [customSidebar]="customSidebar"
      [textLayer]="ffDocumentViewerHighlightText()"
      (pdfLoadingStarts)="isPdfLoaded.set(false)"
      (pdfLoaded)="isPdfLoaded.set(true)"
      (currentZoomFactor)="zoomFactor.set($event)"
      [minZoom]="zoomFactorLimits().min"
      [maxZoom]="zoomFactorLimits().max"
      [zoom]="zoomSetting()"
      (zoomChange)="zoomSetting.set($event)"
      [(page)]="page"
    ></ngx-extended-pdf-viewer>
    <!-- PDF Viewer: Sidebar -->
    <ng-template #customSidebar>
      <pdf-sidebar-content
        id="sidebarContainer"
        [customThumbnail]="customThumbnail"
        [hideSidebarToolbar]="true"
      ></pdf-sidebar-content>
    </ng-template>
    <!-- PDF Viewer: Thumbnail -->
    <ng-template #customThumbnail>
      <a class="pdf-viewer-template">
        <div class="thumbnail" data-page-number="PAGE_NUMBER">
          <!-- PDF Viewer: Thumbnail: Pin (hidden by default) -->
          <div class="pin" hidden>
            <svg>
              <use href="/assets/ui-basics.sprites.svg#quote"></use>
            </svg>
          </div>
          <div class="image-container">
            <img class="thumbnailImage" />
          </div>
          <div class="page-number">PAGE_NUMBER</div>
        </div>
      </a>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply relative w-full h-full;
        @apply typo-h1 bg-neutral-100;
        @apply flex items-center justify-center;

        ::ng-deep {
          ngx-extended-pdf-viewer {
            @apply w-full h-full;

            #mainContainer,
            #viewerContainer {
              @apply bg-neutral-100 !important;
            }

            #thumbnailView,
            #viewerContainer {
              @apply hide-scrollbar !important;
            }

            #sidebarContainer {
              #sidebarContainer,
              #sidebarContent {
                @apply bg-transparent -top-5 !important;
              }
              #thumbnailView {
                @apply pl-6 pr-0 pb-28 !important; // Fix for last thumbnail clipping
                .thumbnail {
                  @apply border-none mb-11 relative !important;
                  @apply shadow;
                  &.selected {
                    img {
                      @apply border-0 border-b-4 border-primary-500 !important;
                    }
                    .page-number {
                      @apply font-semibold !important;
                    }
                  }
                  .pin {
                    @apply absolute -top-[0.5rem] -left-[0.75rem];
                    @apply flex justify-center items-center;
                    @apply size-6 bg-gradient-a-diagonal;
                    @apply rounded-l-full rounded-tr-full rounded-br-[3000px];
                    @apply z-10;
                    @apply shadow-md;
                    svg {
                      @apply w-full h-full text-neutral-100;
                    }
                  }
                  .page-number {
                    @apply w-full mt-4 text-center !important;
                    @apply typo-caption text-neutral-700 !important;
                  }
                }
              }
            }

            .textLayer .customHighlight,
            .textLayer .customHighlight.selected {
              @apply bg-primary-500/[16%];
              opacity: 1;
            }
          }
        }
      }

      :host:not(.is-mobile) ::ng-deep ngx-extended-pdf-viewer #viewerContainer {
        @apply left-40 !important;

        .page {
          @apply first:mt-5;
          .canvasWrapper {
            @apply shadow;
          }
        }
      }
    `,
  ],
})
export class DocumentViewerContentComponent {
  public readonly src = input.required<string>();
  public readonly relevantPages = input.required<number[]>();
  public readonly relevantPagesWithCitationsMatches = signal<number[]>([]);
  public readonly page = model<number>(1);
  public readonly relevantTexts = input.required<string[]>();
  public readonly zoomSetting = model.required<DocumentViewerZoomSetting>();
  public readonly zoomFactor = model.required<DocumentViewerZoomFactor>();
  public readonly zoomFactorLimits = input.required<{
    min: number;
    max: number;
  }>();
  public readonly isMobile = input<boolean>(false);
  public readonly isCitationsLoading = input.required<boolean>();

  public readonly PdfSidebarView = PdfSidebarView;
  private readonly ngxExtendedPdfViewerService = inject(
    NgxExtendedPdfViewerService
  );
  private readonly pdfNotificationService = inject(PDFNotificationService);

  private readonly featureFlagsStore =
    inject<Store<fromFeatureFlags.FeatureFlagsState>>(Store);
  private readonly ffDocumentViewerSourcesPin =
    this.featureFlagsStore.selectSignal(
      fromFeatureFlags.featureFlagsFeature.selectFeatureFlag(
        'ff-document-viewer-sources-pin'
      )
    );
  public readonly ffDocumentViewerHighlightText =
    this.featureFlagsStore.selectSignal(
      fromFeatureFlags.featureFlagsFeature.selectFeatureFlag(
        'ff-document-viewer-highlight-text'
      )
    );

  public readonly isLoading = model<boolean>(false);

  public readonly isPdfLoaded = signal(false);
  private readonly haveRelevantPagesBeenRendered = signal(false);
  private readonly isTextSearchComplete = signal(false);

  private readonly isReadyForTextSearch = computed(
    () =>
      this.ffDocumentViewerHighlightText() &&
      this.isPdfLoaded() &&
      !this.isCitationsLoading() &&
      this.haveRelevantPagesBeenRendered()
  );

  private readonly elementRef = inject<ElementRef<HTMLDivElement>>(ElementRef);

  public readonly pagesWithVisiblePins = model<number[]>([]);

  constructor() {
    effect(() => {
      if (this.src()) {
        this.reset();
      }
    });

    effect(() => {
      if (this.ffDocumentViewerHighlightText()) {
        // Since the find is done on all pages, we can have matches that are on irrelevant pages and we don't want to highlight them.
        this.subscribeToRemoveHighlightedTextsOnIrrelevantPages();
      }
    });

    effect(() => {
      if (this.isPdfLoaded() && this.ffDocumentViewerHighlightText()) {
        void this.preRenderRelevantPages();
      }
    });

    // Run the find only if PDF is loaded, citations are loaded, and highlight ff is enabled
    effect(
      () => {
        if (this.isReadyForTextSearch()) {
          void this.findRelevantTexts().finally(() => {
            this.isTextSearchComplete.set(true);
          });
        }
      },
      { allowSignalWrites: true }
    );

    /**
     * Draw pins on the thumbnails of the relevant pages with citations matches,
     * or fallback to drawing pins on all relevant pages if there are no citations matches.
     */
    effect(() => {
      if (this.ffDocumentViewerSourcesPin() && this.isTextSearchComplete()) {
        const relevantPagesWithCitationsMatches =
          this.relevantPagesWithCitationsMatches();
        const relevantPages = this.relevantPages();
        /**
         * If there are citations matches, we draw pins on the thumbnails of the relevant
         * pages with citations matches only.
         */
        if (relevantPagesWithCitationsMatches.length > 0) {
          this.scrollToFirstPage(this.relevantPagesWithCitationsMatches());
          this.hidePinsOnAllPages();
          return this.drawPinsOnPages(relevantPagesWithCitationsMatches);
        }
        /**
         * If there are no citations matches, we draw pins on all relevant pages.
         */
        if (this.relevantPages().length > 0) {
          this.scrollToFirstPage(this.relevantPages());
          return this.drawPinsOnPages(relevantPages);
        }
      }
    });

    effect(() => {
      if (!this.isPdfLoaded()) {
        this.isLoading.set(true);
      } else if (
        this.ffDocumentViewerHighlightText() &&
        !this.isTextSearchComplete()
      ) {
        this.isLoading.set(true);
      } else {
        this.isLoading.set(false);
      }
    });
  }

  private drawPinsOnPages(pages: number[]) {
    const thumbnailsWithPins = getThumbnails(this.elementRef.nativeElement);
    const pagesWithPins: number[] = [];

    thumbnailsWithPins.forEach((thumbnailWithPin) => {
      if (pages.includes(thumbnailWithPin.pageNumber())) {
        thumbnailWithPin.showPin();
        pagesWithPins.push(thumbnailWithPin.pageNumber());
      }
    });

    this.pagesWithVisiblePins.set(pagesWithPins);
  }

  private hidePinsOnAllPages() {
    const thumbnailsWithPins = getThumbnailsWithPins(
      this.elementRef.nativeElement
    );

    thumbnailsWithPins.forEach((thumbnailWithPin) => {
      thumbnailWithPin.hidePin();
    });

    this.pagesWithVisiblePins.set([]);
  }

  private reset() {
    this.isPdfLoaded.set(false);
    this.haveRelevantPagesBeenRendered.set(false);
    this.isTextSearchComplete.set(false);
    this.relevantPagesWithCitationsMatches.set([]);
  }

  private async preRenderRelevantPages() {
    for (const page of this.relevantPages()) {
      const pageIndex = page - 1;
      if (!this.ngxExtendedPdfViewerService.hasPageBeenRendered(pageIndex)) {
        // This timeout is here to wait for the render queue to be ready to process new renders
        await new Promise((resolve) => setTimeout(resolve, 100));
        // We resolve with a timeout in case the above timeout wasn't enough
        await resolvePromiseWithTimeout(
          this.ngxExtendedPdfViewerService.renderPage(pageIndex),
          undefined
        );
      }
    }

    this.haveRelevantPagesBeenRendered.set(true);
  }

  private scrollToFirstPage(pageNumbers: number[]) {
    if (pageNumbers.length) {
      const firstPage = Math.min(...pageNumbers);
      this.page.set(firstPage);
    }
  }

  private async findRelevantTexts() {
    const matchCountPerPagePromises = this.ngxExtendedPdfViewerService.find(
      this.relevantTexts(),
      {
        useSecondaryFindcontroller: true,
        highlightAll: true,
        findMultiple: true,
        // We disable the scroll into view because a match can possibly be found in a page that comes
        // before the relevant pages
        dontScrollIntoView: true,
      }
    );

    if (!matchCountPerPagePromises) {
      return;
    }

    await Promise.all(
      matchCountPerPagePromises
        .filter((_, index) => this.relevantPages().includes(index + 1))
        .map((matchCountPerPagePromise, pageIndex) =>
          this.processPageMatches(matchCountPerPagePromise, pageIndex)
        )
    );
  }

  private async processPageMatches(
    matchCountPerPagePromise: Promise<number>,
    pageIndex: number
  ): Promise<void> {
    if (this.relevantPages().includes(pageIndex)) {
      // We observed that sometimes, promises were not resolving on large documents, hence why we
      // added a timeout as a workaround.
      await resolvePromiseWithTimeout(matchCountPerPagePromise, 0);
    }
  }

  /**
   * We subscribe to the event `renderedtextlayerhighlights` to remove the highlights
   * from the irrelevant pages and to update the list of relevant pages with citations matches.
   */
  private subscribeToRemoveHighlightedTextsOnIrrelevantPages() {
    const pdfViewerApplication =
      this.pdfNotificationService.onPDFJSInitSignal();

    pdfViewerApplication?.eventBus?.on(
      'renderedtextlayerhighlights',
      (event: RenderedTextLayerHighlights) => {
        const pageNumber = event.pageIndex + 1;

        /**
         * 1. We need to update the list of relevant pages with citations matches
         * because the text search is done on all pages and we need to know which
         * pages have matches to hide the pins on the irrelevant pages.
         */
        if (
          event.highlights.length > 0 &&
          this.relevantPages().includes(pageNumber) &&
          !this.relevantPagesWithCitationsMatches().includes(pageNumber)
        ) {
          this.relevantPagesWithCitationsMatches.update((prev) => [
            ...prev,
            pageNumber,
          ]);
        }

        event.highlights.forEach((highlight) => {
          /**
           * 2. We remove the highlight from the irrelevant pages.
           */
          if (
            !this.relevantPages().includes(pageNumber) &&
            highlight.classList.contains('customHighlight')
          ) {
            highlight.classList.remove('customHighlight');
          }
        });
      }
    );
  }
}
